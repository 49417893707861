import { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  Image,
  Input,
  Stack,
  Text
} from '@chakra-ui/react'
import { COLORS } from 'utils/styles'
import { ImagesPng } from 'assets/images'
import {
  addImagePosts,
  getNotificationMessage,
  postPosts,
  putPosts,
  setOnPostEditor,
  setPostsTypeStatus,
  setExecAutoArchive
} from 'store'
import {
  ACCEPTED_IMG_LIST,
  PENDING,
  REJECTED,
  SUCCEEDED
} from 'utils/constants'
import { motion } from 'framer-motion'
import { CloseIcon } from 'assets/icons'
import { Affinities } from 'components/UserSection/ProfileBlog/Affinities'
import { CustomDatePicker } from 'components/UserSection/ProfileBlog/CustomDatePicker'
import { LoadingSpinier } from 'common/Loading'
import {
  base64ToFile,
  resizeImage,
  isMobile,
  convertToUTC,
  convertToOwnTimeZone
} from 'utils/helpers'
import { CustomPill } from './styled'
import { TextEditor } from './TextEditor'

const MotionStack = motion(Stack)

export const ProfileBlogForm = ({
  profileId,
  profilePicture,
  editPostData,
  setEditPostData,
  postPostsStatus,
  putPostsStatus,
  addImagePostsStatus,
  affinityList,
  getPostsStatus,
  execAutoArchive
}) => {
  const dispatch = useDispatch()
  const [editMode, setEditMode] = useState(false)
  const [affinitiesArray, setAffinitiesArray] = useState([])
  const [chooseAffi, setChooseAffi] = useState(false)
  const [uploadedImg, setUploadedImg] = useState('')
  const [uploadedImgCount, setUploadedImgCount] = useState(0)
  const parentStackRef = useRef(null)
  const formTitleRef = useRef(null)
  const imageInputRef = useRef(null)
  const [postSubmitType, setPostSubmitType] = useState('')
  const [title, setTitle] = useState('')
  const [body, setBody] = useState([])
  const [postCallOnGoing, setPostCallOnGoing] = useState(false)
  const [pillHoverText, setPillHoverText] = useState('')
  const [pillHoverShow, setPillHoverShow] = useState(false)
  const [chooseDate, setChooseDate] = useState(false)
  const [chosenDate, setChosenDate] = useState('')

  const closeForm = () => {
    dispatch(setOnPostEditor(''))
    setTitle('')
    setBody([])
    setEditMode(false)
    setChooseAffi(false)
    setChooseDate(false)
    setChosenDate(false)
    setAffinitiesArray([])
    setEditPostData({})
    setUploadedImgCount(0)
    setPillHoverShow(false)
  }

  const isBodyEmpty = () => {
    let noContent = 1

    if (body?.blocks) {
      body?.blocks.forEach((item) => {
        if (
          (
            item.type === 'unstyled'
            && item.text.trim() !== ''
          )
          || item.type === 'atomic'
        ) {
          noContent = 0
        }
      })
    }

    return noContent
  }

  const submitForm = async () => {
    if (
      (
        postSubmitType === 'post'
        || postSubmitType === 'plan'
      )
      && (
        title.trim() === ''
        || isBodyEmpty()
      )
    ) {
      dispatch(
        getNotificationMessage({
          type: REJECTED,
          message: 'Title and body field is required',
          isEditProfile: true
        })
      )
      setPostSubmitType('')
    } else if (
      postSubmitType === 'archive'
      && (
        title.trim() === ''
        && isBodyEmpty()
      )
    ) {
      dispatch(
        getNotificationMessage({
          type: REJECTED,
          message: 'Title or body field is required',
          isEditProfile: true
        })
      )
      setPostSubmitType('')
    } else {
      let tempBody = JSON.parse(JSON.stringify(body))
      setPostCallOnGoing(true)

      Promise.all(
        Object.values(tempBody.entityMap).map(async (e) => {
          const preview = await dispatch(addImagePosts({
            image: base64ToFile(e.data.src)
          }))

          e.data.src = preview.payload.fileName
        })
      ).then(async () => {
        if (Object.keys(editPostData).length) {
          const data = {
            type: postSubmitType,
            id: editPostData.profile_id,
            post_id: editPostData.id,
            title,
            body: JSON.stringify(tempBody),
            ...(affinitiesArray.length ? { affinities: [(affinitiesArray.find(() => 1)).id] } : {}),
            ...(postSubmitType === 'plan' ? { planned_at: convertToUTC(chosenDate) } : {})
          }

          await dispatch(putPosts(data))
        } else {
          const data = {
            type: postSubmitType,
            id: profileId,
            title,
            body: JSON.stringify(tempBody),
            ...(affinitiesArray.length ? { affinities: [(affinitiesArray.find(() => 1)).id] } : {}),
            ...(postSubmitType === 'plan' ? { planned_at: convertToUTC(chosenDate) } : {})
          }

          await dispatch(postPosts(data))
        }

        if (postSubmitType === 'post') {
          await dispatch(setPostsTypeStatus('posted'))
        } else if (postSubmitType === 'archive') {
          await dispatch(setPostsTypeStatus('archived'))
        } else if (postSubmitType === 'plan') {
          await dispatch(setPostsTypeStatus('scheduled'))
        }

        setPostCallOnGoing(false)
        setPostSubmitType('')
      }).catch(() => {
        setPostCallOnGoing(false)
        setPostSubmitType('')
      })
    }
  }

  const focusTitleRef = () => {
    if (editMode && formTitleRef.current) {
      formTitleRef.current.blur()
      formTitleRef.current.focus()
    }
  }

  const checkPicture = (reader, files) => {
    reader.onload = (e2) => {
      const imageElement = document.createElement('img')
      imageElement.src = e2.target.result

      imageElement.onload = () => {
        try {
          if (
            files[0]?.size > 500000
            || imageElement?.width > 500
          ) {
            resizeImage(imageElement, 'b', (resizedImage) => {
              if (resizedImage === null) {
                dispatch(
                  getNotificationMessage({
                    type: REJECTED,
                    message: 'Image upload failed',
                    isEditProfile: true
                  })
                )
              } else {
                setUploadedImg(resizedImage)
              }
            })
          } else {
            setUploadedImg(files[0])
          }
        } catch {
          dispatch(
            getNotificationMessage({
              type: REJECTED,
              message: 'Image upload failed',
              isEditProfile: true
            })
          )
        }
      }
    }
  }

  const handleUploadFile = (e) => {
    const { files } = e.target
    const reader = new FileReader()

    if (uploadedImgCount >= 4) {
      dispatch(
        getNotificationMessage({
          type: REJECTED,
          message: 'Maximum of 4 images only',
          isEditProfile: true
        })
      )
    } else if (files.length === 0 || files[0].size > 10000000) {
      dispatch(
        getNotificationMessage({
          type: REJECTED,
          message: 'The uploaded image must not exceed 10MB',
          isEditProfile: true
        })
      )
    } else {
      reader.readAsDataURL(files[0])
      checkPicture(reader, files)
    }

    setTimeout(() => {
      imageInputRef.current.value = ''
    }, 500)
  }

  useEffect(() => {
    if (editMode && formTitleRef.current) {
      focusTitleRef()

      if (Object.keys(editPostData).length === 0) {
        dispatch(setOnPostEditor('post'))
      } else {
        dispatch(setOnPostEditor('put'))
      }
    }
  }, [editMode])

  useEffect(() => {
    if (Object.keys(editPostData).length !== 0) {
      setEditMode(true)
      setTitle(editPostData.title ?? '')
      setBody(editPostData.body)
      setAffinitiesArray(editPostData.affinities)
      focusTitleRef()
      setUploadedImgCount(Object.keys(editPostData.body.entityMap).length)
      dispatch(setOnPostEditor('put'))
    }
  }, [editPostData])

  useEffect(() => {
    if (postPostsStatus === SUCCEEDED) {
      closeForm()
    }
  }, [postPostsStatus])

  useEffect(() => {
    if (putPostsStatus === SUCCEEDED) {
      closeForm()
    }
  }, [putPostsStatus])

  useEffect(() => {
    if (postSubmitType) {
      submitForm()
    }
  }, [postSubmitType])

  useEffect(() => {
    if (execAutoArchive) {
      dispatch(setExecAutoArchive(false))
      setPostSubmitType('archive')
    }
  }, [execAutoArchive])

  const heightAdjustment = () => {
    if (isMobile()) {
      if (chooseDate) {
        return 'calc(100svh - 285px)'
      }

      if (chooseAffi) {
        return 'calc(100svh - 185px)'
      }

      return 'calc(100svh - 335px)'
    }

    if (chooseDate) {
      return 'calc(100svh - 285px)'
    }

    return 'calc(100svh - 335px)'
  }

  return (
    <MotionStack
      initial={{ y: 0 }}
      animate={{
        y: 0,
        height: editMode ? heightAdjustment() : '63px'
      }}
      transition={{
        type: 'spring',
        bounce: 0.2,
        duration: 0.5,
        stiffness: 300,
        damping: editMode ? 20 : 30
      }}
      ref={parentStackRef}
      width="calc(100% + 112px)"
      margin="15.5px auto 0 -56px"
      borderRadius="3px"
    >
      <Stack
        display="flex"
        background={COLORS.grayGradientTopToBot}
        alignItems="center"
        justifyContent="flex-start"
        flexDirection="row"
        padding="8px"
        borderRadius="5px"
      >
        <Image
          boxSize="45px"
          objectFit="cover"
          src={profilePicture || ImagesPng.DefaultImage}
          alt="User Image"
          borderRadius="full"
          display="inline-flex"
        />
        {
          editMode
            ? (
              <Input
                ref={formTitleRef}
                background={COLORS.lighterGray}
                color="rgb(100, 100, 100)"
                padding="8px"
                borderRadius={3}
                flex="1"
                maxWidth="calc(100% - 51px)"
                height="35px"
                display="inline-flex"
                alignItems="center"
                placeholder="Title"
                name="blog-title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            )
            : (
              <Text
                background={COLORS.lighterGray}
                color="rgb(150, 150, 150)"
                padding="8px"
                borderRadius={3}
                flex="1"
                maxWidth="calc(100% - 51px)"
                height="35px"
                display="inline-flex"
                alignItems="center"
                cursor="pointer"
                onClick={() => setEditMode(true)}
                _hover={{
                  background: 'rgb(220, 220, 220)',
                  border: 'solid 1px black'
                }}
                transition="0.3s"
              >
                Create
              </Text>
            )
        }
      </Stack>
      {
        editMode
          ? (
            <>
              <Stack position="relative">
                <Input
                  ref={imageInputRef}
                  type="file"
                  accept={ACCEPTED_IMG_LIST}
                  display="none"
                  onChange={handleUploadFile}
                />
                <TextEditor
                  allowedFiles={ACCEPTED_IMG_LIST}
                  editMode={editMode}
                  editPostData={editPostData}
                  uploadedImg={uploadedImg}
                  setUploadedImgCount={setUploadedImgCount}
                  setBody={setBody}
                  setUploadedImg={setUploadedImg}
                  chooseAffi={chooseAffi}
                  chooseDate={chooseDate}
                />
                <Stack
                  rowGap="10px"
                  spacing="unset"
                  wrap="wrap"
                  direction="row"
                  display={affinitiesArray.length > 0 ? 'flex' : 'none'}
                  position="absolute"
                  left="5px"
                  bottom="5px"
                  zIndex="5"
                >
                  {affinitiesArray.map((item) => (
                    <Stack
                      direction="row"
                      width="max-content"
                      alignItems="center"
                      color="#F8F8FF"
                      border="solid 1px black"
                      borderRadius="5px"
                      bg={COLORS.gold}
                      p="0 2px"
                      data-name="blog-affinity"
                      fontSize="11px"
                      cursor="pointer"
                      pointerEvents={chooseAffi ? 'auto' : 'none'}
                      onClick={() => setAffinitiesArray([])}
                    >
                      <Text data-name="affinity-title">{item.title}</Text>
                      <Stack marginLeft="-8px" marginRight="2px" width="11px" height="11px">
                        <CloseIcon fill="#F8F8FF" />
                      </Stack>
                    </Stack>
                  ))}
                </Stack>
                <Text
                  opacity={pillHoverShow ? 1 : 0}
                  transition="0.5s"
                  position="absolute"
                  backgroundColor="whitesmoke"
                  padding="10px 0px"
                  fontSize="20px"
                  top={pillHoverShow ? '45%' : '0px'}
                  right="60px"
                  left="60px"
                  border="1px solid black"
                  borderRadius="10px"
                  boxShadow="lg"
                  textAlign="center"
                  zIndex={pillHoverShow ? 100 : -100}
                >
                  {pillHoverText}
                </Text>
              </Stack>
              <Stack
                display={chooseDate ? 'none' : 'flex'}
                direction="row"
                padding="2.5px 0px"
                justifyContent="space-between"
                margin="-2.5px 0"
                fontSize="13px"
                overflowX="auto"
                sx={{
                  '::-webkit-scrollbar': {
                    display: 'none'
                  },
                  '-ms-overflow-style': 'none',
                  'scrollbar-width': 'none'
                }}
              >
                <CustomPill
                  data-name="blog-media"
                  onClick={() => imageInputRef.current.click()}
                  onMouseEnter={() => {
                    if (!isMobile()) {
                      setPillHoverText('Upload up to 4 images')
                      setPillHoverShow(true)
                    }
                  }}
                  onMouseLeave={() => {
                    if (!isMobile()) {
                      setPillHoverShow(false)
                    }
                  }}
                  onTouchStart={() => {
                    setPillHoverText('Upload up to 4 images')
                    setPillHoverShow(true)
                  }}
                  onTouchEnd={() => {
                    setPillHoverShow(false)
                  }}
                >
                  Media
                </CustomPill>
                <CustomPill
                  data-name="blog-affinity"
                  onClick={() => {
                    if (chooseAffi) {
                      setChooseAffi(false)
                    } else {
                      setChooseDate(false)
                      setChooseAffi(true)
                    }
                  }}
                  onMouseEnter={() => {
                    if (!isMobile()) {
                      setPillHoverText('Add an affinity')
                      setPillHoverShow(true)
                    }
                  }}
                  onMouseLeave={() => {
                    if (!isMobile()) {
                      setPillHoverShow(false)
                    }
                  }}
                  onTouchStart={() => {
                    setPillHoverText('Add an affinity')
                    setPillHoverShow(true)
                  }}
                  onTouchEnd={() => {
                    setPillHoverShow(false)
                  }}
                >
                  Affinity
                </CustomPill>
                <CustomPill
                  data-name="blog-archive"
                  backgroundColor={
                    isBodyEmpty()
                    && title.trim() === ''
                      ? COLORS.gray_op8 + ' !important'
                      : COLORS.gold + ' !important'
                  }
                  pointerEvents={
                    getPostsStatus !== PENDING
                    && putPostsStatus !== PENDING
                    && postPostsStatus !== PENDING
                    && isBodyEmpty()
                    && title.trim() === ''
                      ? 'none'
                      : 'auto'
                  }
                  onClick={() => setPostSubmitType('archive')}
                  onMouseEnter={() => {
                    if (!isMobile()) {
                      setPillHoverText('Save to archive')
                      setPillHoverShow(true)
                    }
                  }}
                  onMouseLeave={() => {
                    if (!isMobile()) {
                      setPillHoverShow(false)
                    }
                  }}
                  onTouchStart={() => {
                    setPillHoverText('Save to archive')
                    setPillHoverShow(true)
                  }}
                  onTouchEnd={() => {
                    setPillHoverShow(false)
                  }}
                >
                  {
                    postSubmitType === 'archive'
                    && (
                      putPostsStatus !== PENDING
                      || postPostsStatus !== PENDING
                      || addImagePostsStatus !== PENDING
                      || postCallOnGoing
                    )
                      ? (
                        <LoadingSpinier
                          width="15px"
                          height="15px"
                          marginTop="5px"
                        />
                      ) : 'Save'
                  }
                </CustomPill>
                <CustomPill
                  data-name="blog-plan"
                  backgroundColor={
                    isBodyEmpty()
                    || title.trim() === ''
                      ? COLORS.gray_op8 + ' !important'
                      : COLORS.gold + ' !important'
                  }
                  pointerEvents={
                    getPostsStatus !== PENDING
                    && putPostsStatus !== PENDING
                    && postPostsStatus !== PENDING
                    && (
                      isBodyEmpty()
                      || title.trim() === ''
                    )
                      ? 'none'
                      : 'auto'
                  }
                  onClick={() => {
                    setChooseAffi(false)
                    setChooseDate(true)
                  }}
                  onMouseEnter={() => {
                    if (!isMobile()) {
                      setPillHoverText('Schedule future publishing time')
                      setPillHoverShow(true)
                    }
                  }}
                  onMouseLeave={() => {
                    if (!isMobile()) {
                      setPillHoverShow(false)
                    }
                  }}
                  onTouchStart={() => {
                    setPillHoverText('Schedule future publishing time')
                    setPillHoverShow(true)
                  }}
                  onTouchEnd={() => {
                    setPillHoverShow(false)
                  }}
                >
                  Plan
                </CustomPill>
                <CustomPill
                  data-name="blog-post"
                  backgroundColor={
                    isBodyEmpty()
                    || title.trim() === ''
                      ? COLORS.gray_op8 + ' !important'
                      : COLORS.gold + ' !important'
                  }
                  pointerEvents={
                    getPostsStatus !== PENDING
                    && putPostsStatus !== PENDING
                    && postPostsStatus !== PENDING
                    && (
                      isBodyEmpty()
                      || title.trim() === ''
                    )
                      ? 'none'
                      : 'auto'
                  }
                  onClick={() => setPostSubmitType('post')}
                  onMouseEnter={() => {
                    if (!isMobile()) {
                      setPillHoverText('Publish this blog now')
                      setPillHoverShow(true)
                    }
                  }}
                  onMouseLeave={() => {
                    if (!isMobile()) {
                      setPillHoverShow(false)
                    }
                  }}
                  onTouchStart={() => {
                    setPillHoverText('Publish this blog now')
                    setPillHoverShow(true)
                  }}
                  onTouchEnd={() => {
                    setPillHoverShow(false)
                  }}
                >
                  {
                    postSubmitType === 'post'
                    && (
                      putPostsStatus === PENDING
                      || postPostsStatus === PENDING
                      || addImagePostsStatus === PENDING
                      || postCallOnGoing
                    )
                      ? (
                        <LoadingSpinier
                          width="15px"
                          height="15px"
                          marginTop="5px"
                        />
                      ) : 'Post'
                  }
                </CustomPill>
                <CustomPill
                  data-name="blog-cancel"
                  pointerEvents={putPostsStatus !== PENDING && postPostsStatus !== PENDING ? 'auto' : 'none'}
                  onClick={() => closeForm()}
                  onMouseEnter={() => {
                    if (!isMobile()) {
                      setPillHoverText('Discard changes')
                      setPillHoverShow(true)
                    }
                  }}
                  onMouseLeave={() => {
                    if (!isMobile()) {
                      setPillHoverShow(false)
                    }
                  }}
                  onTouchStart={() => {
                    setPillHoverText('Discard changes')
                    setPillHoverShow(true)
                  }}
                  onTouchEnd={() => {
                    setPillHoverShow(false)
                  }}
                >
                  Cancel
                </CustomPill>
              </Stack>
              {
                chooseAffi
                  ? (
                    <Affinities
                      affinitiesArray={affinitiesArray}
                      setAffinitiesArray={setAffinitiesArray}
                      affinityList={affinityList}
                    />
                  ) : ''
              }
              {
                chooseDate
                  ? (
                    <CustomDatePicker
                      setChooseDate={setChooseDate}
                      setPostSubmitType={setPostSubmitType}
                      setChosenDate={setChosenDate}
                      setPillHoverShow={setPillHoverShow}
                      setPillHoverText={setPillHoverText}
                      postSubmitType={postSubmitType}
                      postPostsStatus={postPostsStatus}
                      putPostsStatus={putPostsStatus}
                      addImagePostsStatus={addImagePostsStatus}
                      postCallOnGoing={postCallOnGoing}
                      isBodyEmpty={isBodyEmpty}
                      title={title}
                      getPostsStatus={getPostsStatus}
                      plannedAtOrig={
                        Object.keys(editPostData).length !== 0
                        && editPostData.is_planned
                          ? convertToOwnTimeZone(editPostData.planned_at)
                          : ''
                      }
                    />
                  )
                  : ''
              }
            </>
          )
          : ''
      }
    </MotionStack>
  )
}
