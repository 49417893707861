import { useDispatch } from 'react-redux'
import { setPostsTypeStatus } from 'store'
import { COLORS } from 'utils/styles'
import {
  CustomBtn,
  CustomMainBox
} from './styled'

export const PostTypes = ({ postsTypeStatus }) => {
  const dispatch = useDispatch()

  const setTypeStatus = (typeStatus) => {
    dispatch(setPostsTypeStatus(typeStatus))
  }

  return (
    <CustomMainBox>
      <CustomBtn
        initial={{
          background: postsTypeStatus === 'archived'
            ? 'linear-gradient(180deg, rgb(255, 215, 0) 0%, rgb(255, 215, 0) 100%)'
            : COLORS.whiteBlackGradientLeftToRight
        }}
        animate={{
          background: postsTypeStatus === 'archived'
            ? COLORS.whiteBlackGradientLeftToRight
            : 'linear-gradient(180deg, rgb(255, 215, 0) 0%, rgb(255, 215, 0) 100%)'
        }}
        pointerEvents={postsTypeStatus === 'archived' ? 'none' : 'auto'}
        onClick={() => setTypeStatus('archived')}
      >
        Archived
      </CustomBtn>

      <CustomBtn
        initial={{
          background: postsTypeStatus === 'scheduled'
            ? 'linear-gradient(180deg, rgb(255, 215, 0) 0%, rgb(255, 215, 0) 100%)'
            : COLORS.whiteBlackGradientLeftToRight
        }}
        animate={{
          background: postsTypeStatus === 'scheduled'
            ? COLORS.whiteBlackGradientLeftToRight
            : 'linear-gradient(180deg, rgb(255, 215, 0) 0%, rgb(255, 215, 0) 100%)'
        }}
        pointerEvents={postsTypeStatus === 'scheduled' ? 'none' : 'auto'}
        onClick={() => setTypeStatus('scheduled')}
      >
        Scheduled
      </CustomBtn>

      <CustomBtn
        initial={{
          background: postsTypeStatus === 'posted'
            ? 'linear-gradient(180deg, rgb(255, 215, 0) 0%, rgb(255, 215, 0) 100%)'
            : COLORS.whiteBlackGradientLeftToRight
        }}
        animate={{
          background: postsTypeStatus === 'posted'
            ? COLORS.whiteBlackGradientLeftToRight
            : 'linear-gradient(180deg, rgb(255, 215, 0) 0%, rgb(255, 215, 0) 100%)'
        }}
        pointerEvents={postsTypeStatus === 'posted' ? 'none' : 'auto'}
        onClick={() => setTypeStatus('posted')}
      >
        Posted
      </CustomBtn>
    </CustomMainBox>
  )
}
