import { useEffect, useRef, useState } from 'react'
import {
  Box, Input, Stack
} from '@chakra-ui/react'
import { isMobile } from 'utils/helpers'
import { COLORS } from 'utils/styles'

export const Affinities = ({
  affinitiesArray,
  setAffinitiesArray,
  affinityList
}) => {
  const [affinitiesInput, setAffinitiesInput] = useState('')

  const affinitiesListRef = useRef(null)
  const inputRef = useRef(null)

  const filterAffinityList = (affinities = affinityList) => affinities.map((item) => (
    <Box
      onClick={() => setAffinitiesArray([{ id: item.id, title: item.title }])}
      p="3px 6px"
      cursor="pointer"
      borderRadius="5px"
      bg={COLORS.gold}
      width="max-content"
      alignItems="center"
      name={item.title}
      fontSize="13px"
    >
      {item.title}
    </Box>
  ))

  const [filteredAffinityList, setFilteredAffinityList] = useState(filterAffinityList())

  useEffect(() => {
    let affinityListTemp = affinityList
    const tempAffinitiesInput = affinitiesInput.toLowerCase()

    if (affinitiesInput !== '') {
      affinityListTemp = affinityListTemp.filter((item) => {
        const title = item.title.toLowerCase()

        return tempAffinitiesInput === title.substring(0, tempAffinitiesInput.length)
      })
    }

    setFilteredAffinityList(filterAffinityList(affinityListTemp))
  }, [affinitiesInput, affinitiesArray])

  return (
    <Stack direction="column" spacing="10px">
      <Box
        display="flex"
        justifyContent="center"
      >
        <Input
          ref={inputRef}
          placeholder="Tag your article with one affinity"
          value={affinitiesInput}
          onChange={(e) => setAffinitiesInput(e.target.value)}
          name="profile_affinities"
          width="85%"
          height="35px"
          fontSize="13px"
          background={COLORS.lighterGray}
        />
      </Box>

      <Box
        display="flex"
        justifyContent="center"
      >
        <Stack
          ref={affinitiesListRef}
          gap="10px"
          spacing="0px"
          direction="row"
          flexWrap="wrap"
          data-name="affinityList"
          maxHeight={isMobile() ? '5em' : '14em'}
          width="85%"
          overflowY="scroll"
          background={COLORS.lighterGray}
          padding="5px"
          borderRadius="0 0 5px 5px"
          marginTop="-10px"
        >
          { filteredAffinityList }
        </Stack>
      </Box>
    </Stack>
  )
}
