import { Box, Button } from '@chakra-ui/react'
import { motion } from 'framer-motion'
import styled from 'styled-components'

const MotionBtn = motion(Button)

export const CustomMainBox = styled(Box)`
  gap: 0;
  display: flex;
  direction: row;
  width: calc(100% + 116px);
  margin: 15px auto 0 -56px;
`

export const CustomBtn = styled(MotionBtn)`
  width: 33%;
  border: 1px black solid;
  border-radius: 3px;
  padding: 4px 10px;
  cursor: pointer;
  text-align: center;
  transitions: 1s;
  white-space: nowrap;
  font-weight: 500 !important;
`
