import { BlogList } from 'components/Notifications/CommunityBlogs/BlogList'
import { Comments } from 'components/Notifications/CommunityBlogs/Comments'
import { Stack } from '@chakra-ui/react'

export const MarkerBlogPane = ({
  activeProfile,
  blogParent,
  blogSection,
  commentsList,
  commentsOption,
  getPostsStatus,
  posts,
  profileItem,
  getCommentsStatus,
  postCommentsStatus,
  putCommentsStatus,
  subscribeToProfileStatus,
  setBlogSection,
  widen
}) => (
  <Stack
    className="popup-blog-parent"
    overflowY="scroll"
    marginBottom="14px"
    style={{
      scrollbarWidth: 'none',
      msOverflowStyle: 'none'
    }}
  >
    {
      blogSection === 'comments'
        ? (
          <Comments
            activeProfile={activeProfile}
            blogParent={blogParent}
            comments={commentsList}
            commentsOption={commentsOption}
            getCommentsStatus={getCommentsStatus}
            postCommentsStatus={postCommentsStatus}
            putCommentsStatus={putCommentsStatus}
            postsOption={{
              type: 'marker'
            }}
            widen={widen}
          />
        ) : (
          <BlogList
            activeProfile={activeProfile}
            getPostsStatus={getPostsStatus}
            posts={posts}
            postsOption={{
              type: 'marker'
            }}
            profileItem={profileItem}
            subscribeToProfileStatus={subscribeToProfileStatus}
            setBlogSection={setBlogSection}
            widen={widen}
          />
        )
    }
  </Stack>
)
