import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Drawer,
  IconButton,
  Stack,
  Text
} from '@chakra-ui/react'
import {
  ArrowIcon,
  ResizeIcon,
  ShrinkIcon
} from 'assets/icons'
import {
  PENDING,
  REJECTED
} from 'utils/constants'
import { BlogList } from 'components/Notifications/CommunityBlogs/BlogList'
import { Comments } from 'components/Notifications/CommunityBlogs/Comments'
import { COLORS } from 'utils/styles'
import {
  usersSelectors,
  setOpenPopupBlogs,
  getNotificationMessage,
  setComments,
  getComments,
  setBlogParent
} from 'store'
import {
  StyledDrawerContent
} from './styled'

export const BlogModal = () => {
  const dispatch = useDispatch()
  const {
    activeProfile,
    blogParent,
    comments,
    commentsOption,
    getCommentsStatus,
    getPostsStatus,
    postCommentsStatus,
    putCommentsStatus,
    posts,
    profileItem,
    openPopupBlogs,
    subscribeToProfileStatus,
    chosenNotifBlog
  } = useSelector(usersSelectors)

  const drawerRef = useRef()

  const [blogSection, setBlogSection] = useState('posts')
  const [widen, setWiden] = useState(false)
  const [widenAnimation, setWidenAnimation] = useState(false)
  const [animateOut, setAnimateOut] = useState(false)

  const shouldWiden = () => widen && widenAnimation && window.innerWidth > 900

  const closeDrawer = () => {
    setWidenAnimation(false)
    setWiden(false)
    setAnimateOut(true)
    setTimeout(() => {
      setAnimateOut(false)
      dispatch(setOpenPopupBlogs(false))
    }, 500)
  }

  const handleBlogBackBtn = () => {
    if (blogParent.length > 1) {
      if (getCommentsStatus === PENDING) {
        dispatch(getNotificationMessage({
          type: REJECTED,
          message: 'Please finish loading first before returning to previous blog post or comment',
          isEditProfile: true
        }))
      } else {
        const parentData = blogParent[blogParent.length - 1]

        dispatch(setComments([]))
        dispatch(getComments({
          type: parentData.parent_type,
          ...(parentData.parent_type === 'post' ? { post_id: parentData.post_id } : { comment_id: parentData.parent_comment_id })
        }))

        dispatch(setBlogParent(blogParent.slice(0, -1)))
      }
    } else {
      setBlogSection('posts')
    }
  }

  useEffect(() => {
    setTimeout(() => setWidenAnimation(true), 500)
  }, [])

  return (
    <Drawer
      finalFocusRef={drawerRef}
      isOpen={openPopupBlogs}
      onClose={() => closeDrawer()}
      placement="bottom"
      size="sm"
      closeOnEsc={false}
      boxShadow="2xl"
    >
      <StyledDrawerContent
        initial={{
          width: shouldWiden() && widenAnimation ? '365px' : '',
          ...(animateOut ? { y: 0 } : { y: '70vh', opacity: 0 })
        }}
        animate={{
          width: shouldWiden() && widenAnimation ? '70vw' : '365px',
          ...(animateOut ? { y: '70vh' } : { y: 0, opacity: 1 })
        }}
        transition={{
          type: 'spring',
          stiffness: 190,
          damping: 22
        }}
        style={{
          opacity: 0
        }}
        gap={blogSection === 'posts' ? '1em' : 0}
      >
        <Stack
          alignItems="center"
          justifyContent="space-between"
          direction="row"
          p="0px 5px"
          background={COLORS.goldGradientLeftToRight}
          minHeight="48px"
          borderRadius="10px 10px 0 0"
          marginTop="-1px"
          position="sticky"
          top="-0.2"
          zIndex="5"
        >
          <IconButton
            onClick={() => {
              setWidenAnimation(true)
              setWiden(!widen)
            }}
            _hover={{ background: COLORS.gold_hover }}
            background="unset"
            icon={<ResizeIcon />}
            id="resize-button"
            outline="none"
            boxShadow="none"
            display={widen ? 'none' : 'flex'}
            css={{
              ':focus': {
                outline: 'none',
                boxShadow: 'none'
              }
            }}
          />
          <IconButton
            onClick={() => setWiden(!widen)}
            _hover={{ background: COLORS.gold_hover }}
            background="unset"
            icon={<ShrinkIcon />}
            id="shrink-button"
            outline="none"
            boxShadow="none"
            display={widen ? 'auto' : 'none'}
            css={{
              ':focus': {
                outline: 'none',
                boxShadow: 'none'
              }
            }}
          />

          <Text
            maxWidth="70%"
            fontSize="24px"
            textAlign="center"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            overflow="hidden"
          >
            {chosenNotifBlog?.title}
          </Text>

          <IconButton
            onClick={() => {
              if (blogSection === 'posts') {
                closeDrawer()
              } else {
                handleBlogBackBtn()
              }
            }}
            _hover={{ background: COLORS.gold_hover }}
            background="unset"
            transform={blogSection === 'comments' ? 'rotate(180deg)' : 'rotate(-90deg)'}
            icon={<ArrowIcon height="15px" />}
            id="back-button"
            outline="none"
            boxShadow="none"
            css={{
              ':focus': {
                outline: 'none',
                boxShadow: 'none'
              }
            }}
          />
        </Stack>

        <Stack>
          {
            blogSection === 'comments'
              ? (
                <Comments
                  activeProfile={activeProfile}
                  blogParent={blogParent}
                  comments={comments}
                  commentsOption={commentsOption}
                  getCommentsStatus={getCommentsStatus}
                  postCommentsStatus={postCommentsStatus}
                  putCommentsStatus={putCommentsStatus}
                  postsOption={{
                    type: chosenNotifBlog.type
                  }}
                  widen={widen}
                />
              ) : (
                <BlogList
                  activeProfile={activeProfile}
                  getPostsStatus={getPostsStatus}
                  posts={posts}
                  postsOption={{
                    type: chosenNotifBlog.type
                  }}
                  profileItem={profileItem}
                  chosenNotifBlog={chosenNotifBlog}
                  subscribeToProfileStatus={subscribeToProfileStatus}
                  setBlogSection={setBlogSection}
                  widen={widen}
                />
              )
          }
        </Stack>
      </StyledDrawerContent>
    </Drawer>
  )
}
