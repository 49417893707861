import { Button } from '@chakra-ui/react'
import { COLORS } from 'utils/styles'
import styled from 'styled-components'

export const CustomPill = styled(Button)`
  border-radius: 9999px !important;
  background-color: ${COLORS.gold} !important;
  padding: 6px 10px;
  cursor: pointer;
  text-align: center;
  transition: 0.3s;
  width: 100px;
  height: auto !important;
  font-weight: 500 !important;
  font-size: 15px !important;

  @media (max-width: 473px) {
    font-size: 12.5px !important;
  }

  @media (max-width: 380px) {
    font-size: 12px !important;
  }

  :hover {
    background: ${COLORS.gold_hover};
  }
`
