import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  Box,
  Drawer,
  DrawerBody,
  DrawerContent,
  IconButton,
  Stack,
  Text,
  useDisclosure,
  useOutsideClick
} from '@chakra-ui/react'
import { ArrowIcon } from 'assets/icons'
import {
  handleOpenModal,
  getComments,
  getNotificationMessage,
  setBlogParent,
  setComments,
  setProfileItemId,
  usersSelectors,
  deleteProfileItem,
  setNotificationSectionPage,
  getPosts,
  setPosts
} from 'store'
import { clearXmppPushNotification } from 'store/chat/slice'

import { chatCredentialsSelectors } from 'store/chat/selectors'
import {
  MODAL_SECTION,
  NotificationSections,
  PENDING,
  REJECTED
} from 'utils/constants'
import { createValidFormatData, dateNumberFormat, isAuth } from 'utils/helpers'
import { motion } from 'framer-motion'
import { COLORS } from 'utils/styles'

import { Notifications } from 'components/Notifications'
import { GuestNotification } from './GuestNotifications'

const MotionDrawerContent = motion(DrawerContent)

/* eslint-disable */
export const Footer = () => {
  const {
    adminNotifications,
    friendsNotifications,
    activeProfile,
    getCommentsStatus,
    notificationSectionPage,
    notificationSectionPageTitle,
    blogParent
  } = useSelector(usersSelectors)

  const {
    xmppPushNotification
  } = useSelector(chatCredentialsSelectors)

  const { onOpen, isOpen, onClose } = useDisclosure()

  const dispatch = useDispatch()
  const btnRef = useRef()
  const footerRef = useRef()
  const navigate = useNavigate()
  let touchStart, touchEnd

  const [dynamicIsOpen, setDynamicIsOpen] = useState(false)

  if (localStorage.getItem('is-notif-modal-open') === null) {
    localStorage.setItem('is-notif-modal-open', 'false')
  }
  
  useEffect(() => {
    const id = localStorage.getItem('id')

    if (id !== null) {
      setDynamicIsOpen(false)

      localStorage.removeItem('id')
    }
    // TODO: revise 'dynamicIsOpen' usage
    // }, [localStorage.getItem('id')])
  }, [])

  const notificationsValue = adminNotifications
    && createValidFormatData(adminNotifications)?.map((item) => item?.value)

  const allNotifications = notificationsValue?.flat()?.filter((n) => n?.is_viewed === 0)

  const lastNotice = allNotifications?.reduce((acc, curr) => {
    const first = dateNumberFormat(new Date(acc?.data))
    const last = dateNumberFormat(new Date(curr?.data))

    return first > last ? acc : curr
  }, 0)

  const clearOptions = (isNotifModalOpen) => {
    if (activeProfile?.id) {
      if (isNotifModalOpen) {
        // If the marker popup is opened via the url,
        // this will ensure that the marker popup is closed
        // before opening the notification modal 
        document.querySelector('div.leaflet-container').click()
      }

      localStorage.removeItem('id')
      localStorage.removeItem('openedNotifId')
      dispatch(handleOpenModal({ isOpen: isNotifModalOpen, section: MODAL_SECTION?.RightSection }))
      navigate('/')
      setTimeout(() => setDynamicIsOpen(isNotifModalOpen), 50)
    }
  }

  useOutsideClick({
    ref: footerRef,
    handler: () => {
      // console.log(profileItem)
      if (localStorage.getItem('id') && isOpen === true) {
        dispatch(setProfileItemId(null))
        dispatch(deleteProfileItem())
        localStorage.setItem('isBlockedNotification', 'false')
        localStorage.setItem('isNotifOpen', 'false')
        localStorage.removeItem('id')
      }
      
      if (
        localStorage.getItem('isNotifOpen') === true
        || localStorage.getItem('isNotifOpen') === 'true'
      ) {
        dispatch(setNotificationSectionPage(NotificationSections.NOTIFICATIONS))
      }
    }
  })

  const notifMsg = (
    (friendsNotifications.length > 0 || xmppPushNotification !== null) || lastNotice
    ? 'New Notifications'
    : 'No New Notification'
  )
  const notifShadow = (
    (friendsNotifications.length > 0 || xmppPushNotification !== null) || lastNotice
    ? '0 3px 3px 0 rgba(108, 122, 137, .7)'
    : ''
  )

  const header = () => {
    if (
      notificationSectionPage === NotificationSections.BLOG_LIST
      || notificationSectionPage === NotificationSections.BLOG_COMMENTS_FOR_POST
    ) {
      return `${notificationSectionPageTitle}`
    }

    if (notificationSectionPage === NotificationSections.BLOG_PREFERENCE) {
      return 'Blog'
    }

    return 'Notifications'
  }

  return (
    <Stack position="relative" background={COLORS.gold} width="100%" height="50px" data-name="notificationBar">
      {isAuth() ? (
        <>
          <Box
            position="absolute"
            top="50%"
            left="50%"
            transform="translate(-50%, -50%)"
            cursor="pointer"
            onClick={() => {
              // This is to prevent profile card and chat modal being open at the same time
              document.querySelector('div.leaflet-container').click()
              dispatch(setNotificationSectionPage(NotificationSections.NOTIFICATIONS))
              dispatch(clearXmppPushNotification())
              localStorage.setItem('isNotifOpen', 'true')
              clearOptions(true)
              onOpen()
            }}
          >
            <Box
              height="40px"
              padding="10px 61px"
              borderRadius="5px"
              backgroundColor="white"
              width="396px"
              fontSize="20px"
              lineHeight="22.5px"
              boxShadow={ notifShadow }
              sx={{
                '@media (max-width: 768px)': {
                  width: '385px'
                },
                '@media (max-width: 480px)': {
                  width: '306px',
                  fontSize: "15px"
                },
                '@media (max-width: 390px)': {
                  width: '266px',
                  fontSize: "15px"
                }
              }}
            >
              <center>
                { notifMsg }
              </center>
            </Box>
          </Box>

          <Drawer
            isOpen={dynamicIsOpen}
            placement="right"
            onClose={() => {
              clearOptions(false)
              onClose()
              localStorage.setItem('isBlockedNotification', 'false')
              localStorage.setItem('isNotifOpen', 'false')
            }}
            finalFocusRef={btnRef}
            size="sm"
          >
            <MotionDrawerContent
              initial={{ x: 100 }}
              animate={{ x: 0 }}
              exit={{ x: 1000 }}
              transition={{
                type: 'spring',
                stiffness: 200,
                damping: 19
              }}
              sx={{
                width: '95% !important'
              }}
              borderRadius="10px"
              overflow="hidden"
              marginBlock="60px"
              marginInline="10px"
              background="#c4c3c7"
              maxWidth="450px"
              ref={footerRef}
            >
              <Stack
                spacing="43px"
                alignItems="center"
                justifyContent="space-between"
                direction="row"
                p="5px 0"
                background={COLORS.goldGradientLeftToRight}
                height="48px"
                onTouchStart={(e) => {
                  touchStart = e?.targetTouches[0].clientX              
                }}
                onTouchMove={(e) => {
                  touchEnd = e?.targetTouches[0].clientX
                }}
                onTouchEnd={(e) => {
                  if (touchStart - touchEnd < -45) {
                    clearOptions(false)
                    onClose()
                    dispatch(setProfileItemId(null))
                    dispatch(deleteProfileItem())
                    localStorage.setItem('isRequestModalOpen', 'false')
                    localStorage.setItem('isBlockedNotification', 'false')
                    localStorage.setItem('isNotifOpen', 'false')
                    localStorage.removeItem('id')
                  }                
                }}
              >
                <div />
                <Text
                  whiteSpace="nowrap"
                  fontSize="24px"
                  textOverflow="ellipsis"
                  overflow="hidden"
                  fontWeight={600}
                  paddingLeft="50px"
                >
                  {header()}
                </Text>

                <IconButton
                  _hover={{ background: COLORS.gold_hover }}
                  background="unset"
                  transform="rotate(180deg)"
                  style={{
                    marginRight: '5px',
                    borderRadius: '5px',
                    height: '40px'
                  }}
                  icon={<ArrowIcon />}
                  onClick={() => {
                    if (
                      notificationSectionPage === NotificationSections.BLOG_COMMENTS_FOR_POST
                      && blogParent.length > 1
                    ) {
                      if (getCommentsStatus === PENDING) {
                        dispatch(getNotificationMessage({
                          type: REJECTED,
                          message: 'Please finish loading first before returning to previous blog post or comment',
                          isEditProfile: false
                        }))
                      } else {
                        const parentData = blogParent[blogParent.length - 1]

                        dispatch(setComments([]))
                        dispatch(getComments({
                          type: parentData.parent_type,
                          ...(parentData.parent_type === 'post' ? { post_id: parentData.post_id } : { comment_id: parentData.parent_comment_id })
                        }))

                        dispatch(setBlogParent(blogParent.slice(0, -1)))
                      }
                    } else if (
                      notificationSectionPage === NotificationSections.BLOG_COMMENTS_FOR_POST
                      && blogParent.length === 1
                    ) {
                      const parent = blogParent[0]
                      dispatch(setPosts([]))

                      let data = {}
                      if (parent.type === 'friends') {
                        data = {
                          type: 'friends'
                        }
                      } else if (parent.type === 'affinity') {
                        data = {
                          type: 'affinity',
                          id: blogParent[0].affinities[0].id
                        }
                      } else if (parent.type === 'profile') {
                        data = {
                          type: 'profile',
                          id: blogParent[0].profile_id
                        }
                      } else if (parent.type === 'subscribed') {
                        data = {
                          type: 'subscribed',
                          id: blogParent[0].profile_id
                        }
                      }

                      dispatch(getPosts(data))
                      dispatch(setNotificationSectionPage(NotificationSections.BLOG_LIST))
                    } else if (notificationSectionPage === NotificationSections.NOTIFICATIONS) {
                      clearOptions(false)
                      onClose()
                      localStorage.setItem('isBlockedNotification', 'false')
                      localStorage.setItem('isNotifOpen', 'false')
                      dispatch(setProfileItemId(null))
                    } else {
                      dispatch(setNotificationSectionPage(NotificationSections.NOTIFICATIONS))
                    }
                  }}
                  id="back-button"
                  outline="none"
                  boxShadow="none"
                  height="32px"
                  borderRadius="0"
                  css={{
                    ':focus': {
                      outline: 'none',
                      boxShadow: 'none'
                    }
                  }}
                />
              </Stack>

              <DrawerBody
                sx={{
                  '::MsOverflowStyle': 'none',
                  '::-webkit-scrollbar': {
                    display: 'none'
                  }
                }}
                p="0"
              >
                <Notifications />
              </DrawerBody>
            </MotionDrawerContent>
          </Drawer>
        </>
      ) : (
        // For Logged out users
        <>
          <Box
              position="absolute"
              top="50%"
              left="50%"
              transform="translate(-50%, -50%)"
              cursor="pointer"
              onClick={() => onOpen()}
            >
              <Box
                height="40px"
                padding="10px 61px"
                borderRadius="5px"
                backgroundColor="white"
                width="396px"
                fontSize="20px"
                lineHeight="22.5px"
                boxShadow="0 3px 3px 0 rgba(108, 122, 137, .7)"
                sx={{
                  '@media (max-width: 768px)': {
                    width: '385px'
                  },
                  '@media (max-width: 480px)': {
                    width: '306px',
                    fontSize: "15px"
                  },
                  '@media (max-width: 390px)': {
                    width: '266px',
                    fontSize: "15px"
                  }
                }}
              >
                <center>
                  Instructions
                </center>
              </Box>
            </Box>

            <Drawer
              isOpen={isOpen}
              placement="right"
              onClose={() => {
                // clearOptions(false)
                onClose()
              }}
              finalFocusRef={btnRef}
              size="sm"
            >
              <MotionDrawerContent
                initial={{  x: 100 }}
                animate={{ x: 0 }}
                exit={{ x: 1000 }}
                transition={{
                  type: 'spring',
                  stiffness: 200,
                  damping: 19
                }}
                sx={{
                  width: '95% !important'
                }}
                borderRadius="10px"
                overflow="hidden"
                marginBlock="60px"
                marginInline="10px"
                background="#c4c3c7"
                maxWidth="450px"
                ref={footerRef}
              >
                <Stack
                  spacing="43px"
                  alignItems="center"
                  justifyContent="space-between"
                  direction="row"
                  p="5px 0"
                  background={COLORS.goldGradientLeftToRight}
                  height="48px"
                  onTouchStart={(e) => {
                    touchStart = e?.targetTouches[0].clientX              
                  }}
                  onTouchMove={(e) => {
                    touchEnd = e?.targetTouches[0].clientX
                  }}
                  onTouchEnd={(e) => {
                    if (touchStart - touchEnd < -45) {
                      onClose()
                    }                
                  }}
                >
                  <div />
                  <Text
                    whiteSpace="nowrap"
                    fontSize="24px"
                    fontWeight={600}
                    paddingLeft="50px"
                  >
                    Notifications
                  </Text>

                  <IconButton
                    _hover={{ background: COLORS.gold_hover }}
                    background="unset"
                    transform="rotate(180deg)"
                    style={{
                      marginRight: '5px',
                      borderRadius: '5px',
                      height: '40px'
                    }}
                    icon={<ArrowIcon />}
                    onClick={() => {
                      onClose()
                    }}
                    id="back-button"
                    outline="none"
                    boxShadow="none"
                    css={{
                      ':focus': {
                        outline: 'none',
                        boxShadow: 'none'
                      }
                    }}
                  />
                </Stack>

                <DrawerBody
                  sx={{
                    '::MsOverflowStyle': 'none',
                    '::-webkit-scrollbar': {
                      display: 'none'
                    }
                  }}
                  p="0"
                >
                  <GuestNotification />
                  {/* <Notifications /> */}
                </DrawerBody>
              </MotionDrawerContent>
            </Drawer>
            {/* This will be removed
            <InstructionalVideoModal
              isOpen={isOpen}
              onClose={onClose}
            /> */}
        </>
      )}
    </Stack>
  )
}
