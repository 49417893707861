import {
  useEffect,
  useState
} from 'react'
import {
  AtomicBlockUtils,
  convertFromRaw,
  convertToRaw,
  EditorState
} from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import {
  Image,
  Stack
} from '@chakra-ui/react'
import { motion } from 'framer-motion'
import { isMobile } from 'utils/helpers'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { CloseIcon } from 'assets/icons'

const MotionStack = motion('div')

const ImageComponent = ({ block, contentState, blockProps }) => {
  const entity = contentState.getEntity(block.getEntityAt(0))
  const { src } = entity.getData()
  const [showX, setShowX] = useState(false)

  return (
    <div style={{ textAlign: 'center' }}>
      <Stack
        textAlign="center"
        position="relative"
        display="inline-block"
        onMouseEnter={() => setShowX(true)}
        onMouseLeave={() => setShowX(false)}
      >
        <Image
          src={src}
          maxWidth="100%"
          cursor="default"
        />
        <Stack
          aria-label="Remove image"
          backgroundColor="rgba(255, 255, 255, 0.8)"
          padding="2px"
          position="absolute"
          top="2"
          right="2"
          cursor="pointer"
          borderRadius="5px"
          display={showX ? 'flex' : 'none'}
          onClick={() => blockProps.removeImage(block.getKey())}
        >
          <CloseIcon />
        </Stack>
      </Stack>
    </div>
  )
}

export const TextEditor = ({
  allowedFiles,
  editMode,
  editPostData,
  uploadedImg,
  setUploadedImgCount,
  setBody,
  setUploadedImg,
  chooseAffi,
  chooseDate
}) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty())

  const insertImage = (url) => {
    const contentState = editorState.getCurrentContent()
    const contentStateWithEntity = contentState.createEntity('IMAGE', 'IMMUTABLE', { src: url })
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey()

    const newEditorState = AtomicBlockUtils.insertAtomicBlock(
      editorState,
      entityKey,
      ' '
    )

    setEditorState(EditorState.forceSelection(newEditorState, newEditorState.getSelection()))
  }

  const handleImageUpload = (file) => new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onloadend = () => {
      const url = reader.result
      insertImage(url)
      resolve({ data: { url } })
    }

    reader.onerror = (reason) => reject(reason)

    reader.readAsDataURL(file)
  })

  const removeImage = (blockKey) => {
    const contentState = editorState.getCurrentContent()
    const blockMap = contentState.getBlockMap().delete(blockKey)
    const newContentState = contentState.merge({
      blockMap,
      selectionAfter: contentState.getSelectionAfter()
    })

    const newEditorState = EditorState.push(
      editorState,
      newContentState,
      'remove-range'
    )

    setEditorState(newEditorState)
  }

  const heightAdjustment = () => {
    if (chooseAffi) {
      if (isMobile()) {
        return 'calc(100svh - 510px)'
      }

      return 'calc(100svh - 716px)'
    }

    if (chooseDate) {
      if (isMobile()) {
        if (window.innerWidth <= 380) {
          return 'calc(100svh - 490px)'
        }

        if (window.innerWidth <= 473) {
          return 'calc(100svh - 499px)'
        }

        return 'calc(100svh - 510px)'
      }

      return 'calc(100svh - 515px)'
    }

    if (isMobile()) {
      return 'calc(100svh - 445px)'
    }

    return 'calc(100svh - 443px)'
  }

  useEffect(() => {
    if (uploadedImg !== '') {
      handleImageUpload(uploadedImg)
    }

    setUploadedImg('')
  }, [uploadedImg])

  useEffect(() => {
    setBody(convertToRaw(editorState.getCurrentContent()))
  }, [editorState])

  useEffect(() => {
    if (editMode && editPostData.id) {
      const contentState = convertFromRaw(editPostData.body)
      setEditorState(EditorState.createWithContent(contentState))
    }
  }, [editPostData])

  return (
    <MotionStack
      initial={{ y: 0 }}
      animate={{
        y: 0,
        height: heightAdjustment()
      }}
      transition={{
        type: 'spring',
        bounce: 0.2,
        duration: 0.5,
        stiffness: 300,
        damping: 20
      }}
      border="solid 2px transparent"
      borderRadius="5px"
      _focusWithin={{
        borderColor: 'rgb(49, 130, 206)'
      }}
      height="5em"
      display="flex"
      flexDirection="column"
      overflow="auto"
    >
      <Editor
        editorState={editorState}
        onEditorStateChange={(e) => {
          setEditorState(e)
          setUploadedImgCount(Object.keys(convertToRaw(e.getCurrentContent()).entityMap).length)
        }}
        stripPastedStyles
        blockRendererFn={(block) => {
          if (block.getType() === 'atomic') {
            return {
              component: ImageComponent,
              editable: false,
              props: {
                removeImage
              }
            }
          }
          return null
        }}
        toolbarHidden
        toolbar={{
          image: {
            urlEnabled: false,
            inputAccept: { allowedFiles },
            uploadCallback: handleImageUpload,
            alt: { present: false, mandatory: false }
          }
        }}
        editorStyle={{
          background: 'rgb(246, 246, 246)',
          width: '100%',
          minHeight: '100%',
          overflowY: 'scroll',
          resize: 'none',
          padding: '0 3% 3% 3%',
          borderRadius: '5px',
          outline: 'none',
          transition: 'border-color 0.2s',
          border: 'solid 1px black',
          cursor: 'text'
        }}
        placeholder="Article here"
        name="blog-body"
      />
    </MotionStack>
  )
}
