import { useState } from 'react'
import { Box, Stack, Text } from '@chakra-ui/react'
import { MinusIcon } from 'assets/icons'
import { FILTERS_TYPE } from 'utils/constants'
import { motion } from 'framer-motion'
import { COLORS } from 'utils/styles'

const MotionBox = motion(Box)

export const FilterItem = ({
  item, deleteSearchBarFilter, setIsShow, length
}) => {
  const [animateOut, setAnimateOut] = useState(false)

  const filterColor = () => {
    if (item.type === FILTERS_TYPE.Affinities || item.type === FILTERS_TYPE.CodeWord) {
      return COLORS.gold_op8
    }
    return COLORS.gray_op8
  }

  return (
    <MotionBox
      position="relative"
      height="fit-content"
      marginBottom="16px"
      initial={{ opacity: animateOut ? 1 : 0 }}
      animate={{ opacity: animateOut ? 0 : 1 }}
      transition={{
        type: 'tween',
        duration: 0.2
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        backgroundColor={filterColor()}
        minWidth="200px"
        padding="4px 8px 4px 18px"
        alignItems="center"
        borderRadius="5px"
        onMouseOver={() => setIsShow(item.type === FILTERS_TYPE.Own && true)}
        onMouseOut={() => setIsShow(false)}
      >
        <Stack direction="row" spacing={0} alignItems="center">
          {item.type === FILTERS_TYPE.CodeWord && !item.title.includes('#') ? <Box>#</Box> : null}
          <Text data-name="filterName">{item.title}</Text>
        </Stack>

        <Box
          cursor="pointer"
          onClick={() => {
            setAnimateOut(true)
            setTimeout(() => {
              setAnimateOut(false)
              deleteSearchBarFilter(item.id)
              if (length === 1) {
                localStorage.setItem('is-filtered', false)
              }
            }, 200)
          }}
        >
          <MinusIcon />
        </Box>
      </Stack>
    </MotionBox>
  )
}
