export const ShrinkIcon = () => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="SVGRepo_bgCarrier" strokeWidth="0" />
    <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
    <g id="SVGRepo_iconCarrier">
      <g id="Arrow / Shrink">
        <path id="Vector" d="M5 14H10V19M19 10H14V5" transform="rotate(45, 12, 12)" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" />
      </g>
    </g>
  </svg>
)
