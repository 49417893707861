export const ResizeIcon = ({
  width = '20',
  height = '20'
}) => (
  <svg
    fill="#000000"
    height={height}
    width={width}
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 384.792 384.792"
  >
    <g
      id="SVGRepo_bgCarrier"
      strokeWidth="0"
    />
    <g
      id="SVGRepo_tracerCarrier"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <g id="SVGRepo_iconCarrier">
      <g>
        <path
          id="Arrows_Left_x2F_Right"
          d="M86.436,285.188c4.704,4.752,12.319,4.74,17.011,0c4.704-4.74,4.704-12.439,0-17.179 l-62.558-63.46h303.007l-62.558,63.46c-4.704,4.74-4.704,12.439,0,17.179s12.319,4.74,17.011,0l82.997-84.2 c4.56-4.644,4.632-12.512-0.012-17.191l-82.997-84.188c-4.704-4.74-12.319-4.74-17.011,0c-4.692,4.752-4.704,12.439,0,17.191 l62.558,63.448H40.89l62.558-63.46c4.704-4.752,4.692-12.439,0-17.179c-4.704-4.752-12.319-4.752-17.011,0l-82.997,84.2 c-4.776,4.812-4.391,12.367,0,17.191L86.436,285.188z"
        />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
      </g>
    </g>
  </svg>
)
