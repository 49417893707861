export const COLORS = {
  gold: 'rgba(255, 215, 0, 1)',
  gold_hover: 'rgba(225,199,54)',
  goldGradient: 'linear-gradient(180deg, #FFD700 0%, #978939 100%)',
  goldGradientLeftToRight: 'linear-gradient(20deg, #FFD700 30%, rgb(250, 244, 81) 70%)',
  lightGoldGradientLeftToRight: 'linear-gradient(100deg, rgb(259, 239, 123) 0%, rgb(192, 184, 129) 100%)',
  whiteBlackGradientLeftToRight: 'linear-gradient(180deg, rgba(196, 196, 196, 0.8) 0%, rgb(139, 139, 139) 100%)',
  whiteBlackGradientTopToBot: 'linear-gradient(180deg, rgb(125, 125, 125) 0%, rgba(196, 196, 196, 0.8) 50%, rgb(125, 125, 125) 100%)',
  grayGradientTopToBot: 'linear-gradient(180deg, rgb(203, 203, 203) 30%, rgb(228, 228, 228) 100%)',
  darkGrayGradientBotToTop: 'linear-gradient(180deg, rgb(201, 201, 201) 0%, rgb(139, 139, 139) 100%)',
  gold_op8: 'rgba(255, 215, 0, 0.8)',
  metallicGold: 'rgba(225, 199, 54, 1)',
  pureBlack: 'rgba(0, 0, 0, 1)',
  gray: 'rgba(196, 196, 196, 1)',
  gray_op8: 'rgba(196, 196, 196, 0.8)',
  darkGray: 'rgb(139, 139, 139)',
  metallic: 'rgb(217, 217, 217)',
  black: 'rgba(0, 0, 0)',
  lightGray: 'rgba(247, 243, 243, 1)',
  lighterGray: 'rgb(238, 238, 238)',
  semiLightGray: 'rgba(225, 225, 225, 1)',
  lightGreen: 'rgb(232, 255, 231)',
  red: 'rgba(249, 0, 0, 1)'
}
