import { useEffect, useState } from 'react'
import { QRCode } from 'react-qr-code'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { Image, Stack, Text } from '@chakra-ui/react'
import { ImagesPng } from 'assets/images'
import { StyledButtonContainer } from 'common/Buttons/SingleButton/styled'
import dayjs from 'dayjs'
import {
  handleModalTitle,
  usersSelectors,
  setPosts,
  setBlogParent,
  setOnPostEditor,
  setPostsTypeStatus
} from 'store'
import { EDIT_PROFILE, VERIFY_PAGE, PROFILE_BLOG } from 'utils/constants'
import { statusMarkerColor, translateFromLocalUrl } from 'utils/helpers'
import { chatCredentialsSelectors } from 'store/chat/selectors'
import { Affinities } from 'components/UserSection/SingleProfile/Affinities'
import { CodeWords } from 'components/UserSection/SingleProfile/CodeWords'
import { Connections } from 'components/UserSection/SingleProfile/Connections'
import { Coordinates } from 'components/UserSection/SingleProfile/Coordinates'
import { SocialLinks } from 'components/UserSection/SingleProfile/SocialLinks'
import { UserInformation } from 'components/UserSection/SingleProfile/UserInformation'

export const SingleProfile = ({
  setUserSectionPage, selectedUser, handleSelectLocation
}) => {
  const { xmppLiveLocation } = useSelector(chatCredentialsSelectors)
  const dispatch = useDispatch()
  const [wallet, setWallet] = useState('Wallet')
  const [isCopiedLink, setIsCopiedLink] = useState(false)
  const [copyText, setCopyText] = useState('Location')
  const [link, setLink] = useState('')
  const [searchParams] = useSearchParams()
  const [reload, setReload] = useState(0)
  const [showQR, setShowQR] = useState('none')
  const [qrLink, setQRLink] = useState('')

  const {
    full_name,
    affinities,
    social_links,
    profile_picture,
    banner,
    code_words,
    last_accessed,
    coordinates,
    personal_quote,
    past_connections,
    active_connections,
    connections_visibility,
    id,
    location_type
  } = selectedUser

  const {
    activeProfile
  } = useSelector(usersSelectors)

  useEffect(() => {
    dispatch(handleModalTitle(VERIFY_PAGE.FriendsProfile))
    if (coordinates) {
      setLink(window.location.href + '?id=' + id)
    }
  }, [])

  const handleWallet = () => {
    setWallet('Coming Soon')
    setTimeout(() => setWallet('Wallet'), 2000)
  }

  const handleLocation = () => {
    setIsCopiedLink(true)
    setCopyText('Saved to Clipboard')
    handleSelectLocation(selectedUser)
    const urlLink = translateFromLocalUrl()
    const prevId = searchParams.get('id')
    const prevLat = searchParams.get('lat')
    const prevLng = searchParams.get('lng')
    const prevZoom = searchParams.get('zoom')
    if (prevId) {
      navigator.clipboard.writeText((urlLink?.replace('?id=' + prevId, '')) + '?id=' + id)
    } else if (prevLat && prevLng && prevZoom) {
      navigator.clipboard.writeText((urlLink?.replace('?lat=' + prevLat + '&lng=' + prevLng + '&zoom=' + prevZoom, '')) + '?id=' + id)
    } else {
      navigator.clipboard.writeText(urlLink + '?id=' + id)
    }
  }

  const handleQRCode = () => {
    const urlLink = translateFromLocalUrl()
    const prevId = searchParams.get('id')
    const prevLat = searchParams.get('lat')
    const prevLng = searchParams.get('lng')
    const prevZoom = searchParams.get('zoom')
    if (showQR === 'none') {
      setShowQR('inline-block')
      if (prevId) {
        setQRLink((urlLink?.replace('?id=' + prevId, '')) + '?id=' + id)
      } else if (prevLat && prevLng && prevZoom) {
        setQRLink((urlLink?.replace('?lat=' + prevLat + '&lng=' + prevLng + '&zoom=' + prevZoom, '')) + '?id=' + id)
      } else {
        setQRLink(urlLink + '?id=' + id)
      }
    } else {
      setQRLink('')
      setShowQR('none')
    }
  }

  const validDate = dayjs(last_accessed)
  const statusColor = statusMarkerColor(validDate)

  useEffect(() => {
    if (isCopiedLink) {
      const clearSuccess = setTimeout(() => {
        setCopyText('Location')
        setIsCopiedLink(false)
      }, 1000)

      return () => {
        clearTimeout(clearSuccess)
      }
    }
    return undefined
  }, [isCopiedLink])

  useEffect(() => {
    setReload(Math.random())
  }, [selectedUser])

  return (
    <Stack direction="column" spacing="16px">
      {reload && (
        <>
          <UserInformation
            profilePicture={profile_picture}
            fullName={full_name}
            lastAccessed={last_accessed}
            setUserSectionPage={setUserSectionPage}
            quote={personal_quote}
            coverPicture={banner}
          />
          <Connections
            activeConnections={active_connections}
            pastConnections={past_connections}
            isShowConnections={connections_visibility}
            statusColor={statusColor}
          />
          <CodeWords codeWords={code_words} />
          <Affinities affinities={affinities} />
          <SocialLinks socialLinks={social_links} />
          <Stack
            width="100%"
            spacing="16px"
          >
            <StyledButtonContainer
              name="open-profile-blog"
              onClick={() => {
                dispatch(setPosts([]))
                dispatch(setBlogParent([]))
                dispatch(setOnPostEditor(''))
                dispatch(setPostsTypeStatus('posted'))
                dispatch(setUserSectionPage(PROFILE_BLOG))
              }}
            >
              Blog
            </StyledButtonContainer>
            <Stack
              style={{
                height: 'auto',
                margin: '20px auto',
                maxWidth: 128,
                width: '100%'
              }}
              display={showQR}
              alignItems="center"
            >
              <QRCode value={qrLink} size={128} />
            </Stack>
            <StyledButtonContainer
              customStyled={{
                width: 100
              }}
              name="profile-qr-code"
              onClick={() => handleQRCode()}
            >
              <Text
                width="260px"
                sx={{
                  '@media (max-width: 480px)': {
                    width: '150px'
                  }
                }}
              >
                Share Profile
              </Text>
            </StyledButtonContainer>
            <StyledButtonContainer
              name="profile-wallet"
              onClick={() => handleWallet()}
              style={{
                backgroundColor: '#e6e6e6'
                // boxShadow: '1px 1px 0px 0pxrgb(196, 196, 196)'
              }}
            >
              {wallet}
              <Image
                boxSize="20px"
                margin={1}
                // cursor="pointer"
                objectFit="cover"
                src={ImagesPng.LogoHeader}
                alt="Logo"
                borderRadius="full"
              />
            </StyledButtonContainer>
            <StyledButtonContainer
              data-link={id ? link : null}
              onClick={() => handleLocation()}
              name="profile-location"
            >
              {copyText}
            </StyledButtonContainer>
            <StyledButtonContainer name="edit-profile" onClick={() => dispatch(setUserSectionPage(EDIT_PROFILE))}>
              Edit
            </StyledButtonContainer>
          </Stack>
        </>
      )}
    </Stack>
  )
}
