import {
  useEffect,
  useState
} from 'react'
import { useDispatch } from 'react-redux'
import {
  Box,
  Card,
  CardBody,
  CardHeader,
  Center,
  Image,
  Stack,
  Spinner
} from '@chakra-ui/react'
import {
  getBlogPreference,
  getPosts,
  setBlogParent,
  setNotificationSectionPageTitle,
  setPosts,
  setOpenPopupBlogs,
  setChosenNotifBlog
} from 'store'
import { ImagesPng } from 'assets/images'
import {
  PENDING,
  SUCCEEDED
} from 'utils/constants'

export const CommunityBlogs = ({
  NotificationSections,
  preferredBlogs,
  rowTitle,
  setNotificationSectionPage,
  getPostsStatus,
  getBlogPreferenceStatus
}) => {
  const dispatch = useDispatch()
  const [itemData, setItemData] = useState([])
  const [itemTitle, setItemTitle] = useState('')
  const [sectionPage, setSectionPage] = useState('')

  const goToPane = () => {
    dispatch(setNotificationSectionPageTitle(itemTitle))
    dispatch(setNotificationSectionPage(sectionPage))

    setItemTitle('')
    setSectionPage('')
  }

  useEffect(() => {
    if (
      getPostsStatus === SUCCEEDED
      && itemTitle !== ''
      && sectionPage !== ''
    ) {
      dispatch(setChosenNotifBlog(itemData))
      if (window.innerWidth <= 900) {
        goToPane()
      } else {
        document.querySelector('#back-button').click()
        dispatch(setOpenPopupBlogs(true))
      }
    }
  }, [getPostsStatus])

  useEffect(() => {
    if (
      getBlogPreferenceStatus === SUCCEEDED
      && itemTitle !== ''
      && sectionPage !== ''
    ) {
      goToPane()
    }
  }, [getBlogPreferenceStatus])

  return (
    <Card key="community-blogs" boxShadow="none" marginTop="0px !important" background="#c4c3c7">
      <CardHeader display="flex" alignItems="center" padding="0 25px" background="#feeb88">
        <Box textAlign="left" paddingRight="10px">
          {rowTitle}
        </Box>
      </CardHeader>

      <CardBody padding="5px" minHeight="130px">
        <Box
          sx={{
            overflowX: 'auto',
            display: 'flex',
            flexDirection: 'row',
            gap: '0px'
          }}
          css={{
            '&::-webkit-scrollbar': {
              width: '4px',
              height: '10px',
              display: 'block'
            },
            '&::-webkit-scrollbar-track': {
              width: '6px'
            },
            '&::-webkit-scrollbar-thumb': {
              borderRadius: '24px',
              background: 'rgba(255, 215, 0, 1)'
            }
          }}
        >
          <Stack
            spacing="55px"
            id="community-blog-section"
            paddingBottom={preferredBlogs.length > 3 ? '5px' : null}
            marginLeft="5px"
            marginRight="5px"
            minHeight="135px"
            sx={{
              overflowX: 'auto',
              display: 'flex',
              flexDirection: 'row',
              gap: '0px',
              overflowAnchor: 'auto !important'
            }}
            css={{
              '&::-webkit-scrollbar': {
                width: '4px',
                height: '10px',
                display: 'block'
              },
              '&::-webkit-scrollbar-track': {
                width: '6px'
              },
              '&::-webkit-scrollbar-thumb': {
                borderRadius: '24px',
                background: 'rgba(255, 215, 0, 1)'
              }
            }}
          >
            {preferredBlogs.map((item) => (
              <Stack key={item.id}>
                <Box
                  key={item.id}
                  sx={{
                    minWidth: '120px',
                    marginTop: '5px'
                  }}
                  data-name="preferredBlog"
                  position="relative"
                >
                  <Image
                    key={item.id}
                    cursor="pointer"
                    width="108px"
                    height="108px"
                    objectFit="cover"
                    src={item.blogImage ?? ImagesPng.DefaultImage}
                    title={item.title ?? 'blog image'}
                    alt={item.title ?? 'blog image'}
                    borderRadius="5px"
                    pointerEvents={
                      getPostsStatus === PENDING
                      || getBlogPreference === PENDING
                        ? 'none'
                        : 'auto'
                    }
                    onClick={() => {
                      dispatch(setPosts([]))
                      dispatch(setBlogParent([]))

                      if (item.type === null) {
                        dispatch(getBlogPreference())

                        setItemTitle(item.title)
                        setSectionPage(NotificationSections.BLOG_PREFERENCE)
                      } else {
                        dispatch(getPosts({
                          type: item.type,
                          id: item.id
                        }))

                        setItemData(item)
                        setItemTitle(item.title)
                        setSectionPage(NotificationSections.BLOG_LIST)
                      }
                    }}
                  />
                  {
                    (
                      getPostsStatus === PENDING
                      || getBlogPreferenceStatus === PENDING
                    )
                    && item.title === itemTitle
                      ? (
                        <Center position="absolute">
                          <Spinner
                            key={item.id}
                            width="80px"
                            height="80px"
                            position="sticky"
                            marginLeft="14px"
                            marginTop="-108px"
                            zIndex={5}
                          />
                        </Center>
                      ) : ''
                  }
                </Box>
              </Stack>
            ))}
          </Stack>
        </Box>
      </CardBody>
    </Card>
  )
}
