import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { getNotificationMessage } from 'store'
import { REJECTED } from 'utils/constants'
import { isLiteralNumber } from 'utils/helpers'

import { FiltersData } from './FiltersData'
import { Map } from './Map'
import { StyledContentContainer } from './styled'
import { UsersCount } from './UsersCount'
import { BlogModal } from './BlogModal'

export const Content = () => {
  const dispatch = useDispatch()

  const [visibleMarkersCount, setVisibleMarkersCount] = useState([])
  const [showMarkerCount, setShowMarkerCount] = useState('flex')
  const zoom = localStorage.getItem('zoom')
  const isFiltered = localStorage.getItem('is-filtered')

  const [searchParams] = useSearchParams()

  const id = searchParams.get('id')

  if (id !== null) {
    if (isLiteralNumber(id)) {
      localStorage.setItem('id', id)
    } else {
      dispatch(
        getNotificationMessage({
          type: REJECTED,
          message: 'Invalid URL parameters',
          isEditProfile: true
        })
      )
    }
  }

  return (
    <StyledContentContainer>
      <Map
        setVisibleMarkersCount={setVisibleMarkersCount}
        setShowMarkerCount={setShowMarkerCount}
        showMarkerCount={showMarkerCount}
      />
      <FiltersData showMarkerCount={showMarkerCount} />
      <UsersCount
        visibleMarkersCount={visibleMarkersCount}
        showMarkerCount={showMarkerCount}
        zoom={zoom}
        isFiltered={isFiltered}
      />
      <BlogModal />
    </StyledContentContainer>
  )
}
