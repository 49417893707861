import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Box, Center, Image, Stack, Text
} from '@chakra-ui/react'
import { ImagesPng } from 'assets/images'
import {
  selectProfile, showCurrentChatFriendMarkerCoordinate,
  setIsProfileSwitched, chatCredentialsSelectors, usersSelectors
} from 'store'
import { COLORS } from 'utils/styles'
import { v4 as uuid } from 'uuid'
import { changeNumUnread, clearXmppPushNotification } from 'store/chat/slice'
import { LOCATION_TYPE } from 'utils/constants'

import {
  Item, RadioButtonLabel, StyledRadio
} from './styled'

export const ProfilesSection = ({
  array = [], title = '', handleSelectUser, isPending
}) => {
  const [disableSection, setDisableSection] = useState('auto')
  const [sectionOpacity, setSectionOpacity] = useState(1)
  const { xmppLiveLocation } = useSelector(chatCredentialsSelectors)
  const { activeProfile } = useSelector(usersSelectors)

  const dispatch = useDispatch()

  // eslint-disable-next-line import/no-extraneous-dependencies, global-require
  const Favico = require('favico.js-slevomat')

  const handleSelectPage = (user) => {
    if (user.full_name && !user.is_active) {
      dispatch(clearXmppPushNotification())
      dispatch(selectProfile({
        profileId: user.id,
        showNotifMsg: true
      }))
      dispatch(setIsProfileSwitched(true))
      const favico = new Favico({
        animation: 'none'
      })
      window.addEventListener('custom-chat-num_unread', (e) => {
        dispatch(changeNumUnread(e.detail.num_unread))
        if (e.detail.num_unread === 0) {
          document.querySelector('link[rel="icon"]').setAttribute('href', window.location.origin + '/favicon.ico')
        } else {
          favico.badge(e.detail.num_unread)
        }
      })
      if (user?.coordinates !== null) {
        let coord = {
          coord: user.coordinates,
          isStatic: true
        }
        dispatch(showCurrentChatFriendMarkerCoordinate(coord))
      }
    } else if (!user.full_name) {
      handleSelectUser(user)
    }
  }

  useEffect(() => {
    if (title === 'Dating' || title === 'Professional') {
      setDisableSection('none')
      setSectionOpacity(0.5)
    } else {
      setDisableSection('auto')
      setSectionOpacity(1)
    }
  }, [title])

  const profiles = () => array.map((user) => (
    <Stack
      width="calc(100% + 130px)"
      key={uuid()}
      // bg={user.is_active ? COLORS.lightGreen : null}
      sx={{ marginLeft: '-65px !important', pointerEvents: disableSection, opacity: sectionOpacity }}
      // _hover={{
      //   background: COLORS.lightGray,
      //   transition: '0.5s'
      // }}
      wordBreak="break-word"
    >
      <Stack
        justifyContent="space-between"
        cursor="pointer"
        direction="row"
        width="400px"
        margin="auto"
        height="60px"
        sx={{
          '@media (max-width: 320px)': {
            width: '300px'
          },
          '@media (max-width: 450px)': {
            width: '300px'
          }
        }}
      >
        <Stack
          width="100%"
          alignItems="center"
          direction="row"
          spacing="10px"
        >
          {/* <Radio
            defaultChecked={user.is_active}
            onChange={() => handleSelectPage(user)}
            disabled={user.is_active}
            id="email-alerts"
            color="black"
            opacity="1"
          /> */}

          <Item>
            <StyledRadio
              type="radio"
              defaultChecked={user.is_active}
              onChange={() => handleSelectPage(user)}
              disabled={user.is_active}
              color="black"
              name="selected-profile"
              id={'select-profile-' + user.id}
            />
            <RadioButtonLabel htmlFor={'select-profile-' + user.id} />
          </Item>

          <Image
            boxSize="42px"
            objectFit="cover"
            src={user.profile_picture ? user.profile_picture : ImagesPng.DefaultImage}
            alt="Logo"
            borderRadius="full"
            name="logo"
            onClick={() => {
              if (user?.coordinates !== null) {
                if (
                  user?.location_type?.[LOCATION_TYPE.LiveLocation] === 0
                  && user?.full_name === activeProfile?.full_name
                  && Number.isNaN(xmppLiveLocation?.lat) === false
                ) {
                  let coord = {
                    coord: [xmppLiveLocation.lat, xmppLiveLocation.lng],
                    isStatic: false
                  }
                  dispatch(showCurrentChatFriendMarkerCoordinate(coord))
                } else {
                  let coord = {
                    coord: user?.coordinates,
                    isStatic: true
                  }
                  dispatch(showCurrentChatFriendMarkerCoordinate(coord))
                }
              } else if (user?.coordinates === null) {
                handleSelectUser(user)
              }
            }}
          />

          <Box
            bg={user.is_active ? COLORS.lightGray : null}
            _hover={{
              background: COLORS.lightGray,
              transition: '0.5s'
            }}
            h="42px"
            w="100%"
            alignItems="center"
            flex="1"
            paddingY="8px"
            data-name="editSpecificProfile"
            sx={{
              '@media (max-width: 320px)': {
                width: '50px'
              }
            }}
          >
            <Text
              fontSize="16px"
              verticalAlign="center"
              onClick={() => handleSelectUser(user)}
              marginLeft={2}
              name="profile-name"
              width="200px"
              isTruncated
              sx={{
                userSelect: 'none',
                '@media (max-width: 400px)': {
                  width: '120px'
                }
              }}
            >
              {user.full_name ? user.full_name : 'New Profile'}
            </Text>
          </Box>
        </Stack>
        {/* {isHovering === user.id && user.full_name ? (
          <Box display="flex" alignItems="center">
            <Box>
              <Switch
                defaultChecked={user.is_active}
                onChange={() => handleSelectPage(user)}
                disabled={user.is_active}
                id="email-alerts"
                colorScheme="yellow"
              />
            </Box>
          </Box>
        ) : null} */}
      </Stack>
    </Stack>
  ))

  const comingSoon = (showPrompt) => (
    <Text fontSize="14px" opacity={sectionOpacity}>
      { showPrompt ? 'Coming soon' : '' }
    </Text>
  )

  return (
    <Stack
      direction="column"
      width="calc(100% + 100px)"
      spacing="12px"
      bgColor="white"
      sx={{
        marginLeft: '-50px !important',
        paddingY: '1rem'
      }}
      borderRadius="5px"
    >
      <Center>
        <Stack direction="column" width="400px" className="profile-container" spacing="1rem" bgColor="white">
          <Stack
            sx={{
              gap: title === 'Dating' ? '60% !important' : '47% !important',
              '@media (max-width: 480px)': {
                gap: title === 'Dating' ? '35% !important' : '20% !important',
                marginLeft: '50px'
              }
            }}
            alignItems="center"
            direction="row"
            w="100%"
          >
            {/* TODO client asked to comment */}
            {/* <Box>{icon}</Box> */}
            <Text fontWeight={700} fontSize="20px" opacity={sectionOpacity}>
              {title}
            </Text>
            <Stack direction="row">
              <Text
                fontWeight={700}
                fontSize="20px"
                opacity={sectionOpacity}
                right={10}
                width="max-content !important"
                sx={{
                  '@media (max-width: 480px)': {
                    marginLeft: title === 'Dating' ? '-48px' : '-49px'
                  }
                }}
              />
              { comingSoon(isPending) }
            </Stack>
          </Stack>

          {profiles()}

          {/* <Box
          bgColor="white"
          width="calc(100% + 100px)"
          h="300px"
          sx={{
            marginTop: '-260px !important',
            marginLeft: '-50px !important',
            '@media (max-width: 480px)': {
              marginTop: '-293px !important'
            }
          }}
          zIndex={-1}
          borderRadius="5px"
        /> */}
        </Stack>
      </Center>

    </Stack>
  )
}
