import { useState } from 'react'
import { Text, Stack } from '@chakra-ui/react'
import { motion } from 'framer-motion'
import { CreateComment } from 'components/Notifications/CommunityBlogs/Comments/CreateComment'
import { CommentsForPost } from 'components/Notifications/CommunityBlogs/Comments/CommentsForPost'
import { COLORS } from 'utils/styles'

const MotionStack = motion(Stack)

export const Comments = ({
  activeProfile,
  comments,
  getCommentsStatus,
  postCommentsStatus,
  putCommentsStatus,
  blogParent,
  commentsOption,
  postsOption,
  widen
}) => {
  const [editCommentData, setEditCommentData] = useState({})

  const parentData = blogParent[blogParent.length - 1]
  return (
    <MotionStack
      key={parentData?.comment_id ?? parentData?.id}
      initial={{ x: 100 }}
      animate={{ x: 0 }}
      transition={{
        type: 'spring',
        stiffness: 300,
        damping: 60
      }}
      direction="column"
      margin={postsOption.type === 'marker' ? '0' : '16px 0'}
      gap="16px 0"
    >
      <Stack
        display="flex"
        justifyContent="center"
        direction="row"
        margin="0"
      >
        <Text
          background={COLORS.lightGoldGradientLeftToRight}
          padding="2px 5%"
          fontSize={widen ? '23px' : '19px'}
          width="85%"
          borderRadius="5px"
          align="center"
          letterSpacing=".15px"
          fontWeight="400"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          overflow="hidden"
          margin="0 !important"
        >
          {blogParent[blogParent.length - 1].title ?? blogParent[blogParent.length - 1].body}
        </Text>
      </Stack>

      <CreateComment
        activeProfile={activeProfile}
        blogParent={blogParent}
        getCommentsStatus={getCommentsStatus}
        type={commentsOption.type}
        postCommentsStatus={postCommentsStatus}
        putCommentsStatus={putCommentsStatus}
        editCommentData={editCommentData}
        setEditCommentData={setEditCommentData}
      />

      <CommentsForPost
        activeProfile={activeProfile}
        comments={comments}
        getCommentsStatus={getCommentsStatus}
        setEditCommentData={setEditCommentData}
        commentsOption={commentsOption}
        postsOption={postsOption}
        blogParent={blogParent}
        widen={widen}
      />
    </MotionStack>
  )
}
